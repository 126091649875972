import { WebApiResponse } from 'shared/types/WebApiTypes';
import BaseEntity from '../BaseEntity';
import { CreateChat, CreateMessagess } from '../interfaces/Chat';

class Chat extends BaseEntity {
  protected API_SLUG_CHAT: string = 'chats';
  protected API_SLUG_MESSAGES: string = 'messages';
  protected API_SLUG_CHAT_USERS: string = 'chat-users';
  protected API_SLUG_CHAT_USERS_MESSAGES: string = 'chat-user-messages';
  protected API_DEPARTMENTS: string = 'organization-departments';
  protected API_ROLE: string = 'organization-user-roles';
  protected API_SLUG_ADVERT_LEAD_OWNER = 'advert-lead-owner';
  // protected API_SLUG_ADVERT_CUSTOMER = 'advert-customer';

  async getAllChats(data?: any): Promise<WebApiResponse<any>> {
    const { err: errOwnerLeadChat, data: advertOwnerLead } = await this.get(
      `${this.API_SLUG_CHAT}`,
      {
        leadId: data.leadId,
        chatType: 'advertOwnerLead',
      }
    );
    const { err: errTenderLeadChat, data: tenderOwnerLead } = await this.get(
      `${this.API_SLUG_CHAT}`,
      {
        leadId: data.leadId,
        chatType: 'tenderOwnerLead',
      }
    );
    const { err: errLeadOwnerChat, data: advertLeadOwner } = await this.get(
      `${this.API_SLUG_CHAT}`,
      {
        leadId: data.leadId,
        chatType: 'advertLeadOwner',
      }
    );
    const { err: errTenderOwnerChat, data: tenderLeadOwner } = await this.get(
      `${this.API_SLUG_CHAT}`,
      {
        leadId: data.leadId,
        chatType: 'tenderLeadOwner',
      }
    );
    const { err: erradvertChat, data: advertChat } = await this.get(
      `${this.API_SLUG_CHAT}`,
      {
        leadId: data.leadId,
        chatType: 'advertCustomer',
      }
    );
    const { err: errTenderChat, data: tenderChat } = await this.get(
      `${this.API_SLUG_CHAT}`,
      {
        leadId: data.leadId,
        chatType: 'tenderCustomer',
      }
    );
    const { err: errUserChat, data: userChat } = await this.get(
      `${this.API_SLUG_CHAT}`,
      {
        organizationId: data.organizationId,
        chatType: 'inner',
      }
    );

    return {
      data: {
        advertOwnerLead,
        tenderOwnerLead,
        advertLeadOwner,
        tenderLeadOwner,
        advertChat,
        tenderChat,
        userChat,
      },
    };
  }
  async getPartnerChat(id?: any): Promise<WebApiResponse<any>> {
    const { err, data } = await this.get(`${this.API_SLUG_CHAT}`, {
      relationId: id,
      chatType: 'partner',
    });
    if (err) return { err };
    return { data };
  }
  async getChatsList(chatId?: string): Promise<WebApiResponse<any>> {
    const params = chatId ? { chatId } : {};
    const { err, data } = await this.get(this.API_SLUG_CHAT, params);
    if (err) return { err };
    return { data };
  }

  async createChat(params: CreateChat): Promise<WebApiResponse<any>> {
    // params.userId = (await this._getSession())?.user?.id;
    const { err, data } = await this.post(this.API_SLUG_CHAT, params);
    if (err) return { err };
    return { data };
  }

  async removeChat(chatId?: string) {
    await this.del(this.API_SLUG_CHAT, { chatId });
  }

  async getMessagesList(chatId?: string) {
    const params = chatId ? { chatId } : {};
    const { err, data } = await this.get(this.API_SLUG_MESSAGES, params);
    if (err) return { err };
    return { data };
  }
  async createMessages(params: CreateMessagess) {
    const { err, data } = await this.post(this.API_SLUG_MESSAGES, params);
    if (err) return { err };
    return { data };
  }
  async removeMessage(messagesId?: string) {
    await this.del(this.API_SLUG_MESSAGES, { messagesId });
  }
  async getChatUsersList(): Promise<WebApiResponse<any>> {
    const { err, data } = await this.get(this.API_SLUG_CHAT, {
      relation: true,
    });
    if (err) return { err };
    return { data };
  }
  async getChatAdvert(leadId: string): Promise<WebApiResponse<any>> {
    const { err, data } = await this.get(
      `${this.API_SLUG_CHAT}/${this.API_SLUG_ADVERT_LEAD_OWNER}`,
      { leadId }
    );
    if (err) return { err };
    return { data };
  }
  async getChatUsers(filter: {}): Promise<WebApiResponse<any>> {
    const { err, data } = await this.get(this.API_SLUG_CHAT_USERS, filter);
    if (err) return { err };
    return { data };
  }
  async createChatsUsers(params: {}): Promise<WebApiResponse<any>> {
    // params.userId = (await this._getSession())?.user?.id;
    const { err, data } = await this.post(this.API_SLUG_CHAT_USERS, params);
    if (err) return { err };
    return { data };
  }
  async updateChatsUsers(params: {}): Promise<WebApiResponse<any>> {
    // params.userId = (await this._getSession())?.user?.id;
    const { err, data } = await this.patch(this.API_SLUG_CHAT_USERS, params);
    if (err) return { err };
    return { data };
  }
  async removeChatUser(chatUserId: string) {
    await this.del(`${this.API_SLUG_CHAT_USERS}?chatUserId=${chatUserId}`, {});
  }
  async getRoleList(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.API_ROLE, filter);
    return data;
  }
  async getDepartmentsList(filter: {}): Promise<any> {
    const { err, data } = await this.get(this.API_DEPARTMENTS, filter);
    if (err) return { err: { message: 'Неизвестная ошибка' } };
    const newDepartmentList = [];
    for (let index = 0; index < data?.length; index++) {
      const element = data[index];
      const { err: _err, data: _data } = await this.get(this.API_ROLE, {
        organizationDepartmentId: element.id,
        organizationId: element.organizationId,
      });
      if (_data.length) {
        element.employees = _data;
      }
      newDepartmentList.push(element);
    }

    return { data: newDepartmentList };
  }
  async getUnReadMessagesList(filter: {}): Promise<any> {
    const { err, data } = await this.get(
      this.API_SLUG_CHAT_USERS_MESSAGES,
      filter
    );
    if (err) return { err: { message: 'Неизвестная ошибка' } };

    return { data: data };
  }
  async deleteUnReadMessagesList(filter: {}): Promise<any> {
    const { err, data } = await this.del(
      this.API_SLUG_CHAT_USERS_MESSAGES,
      filter
    );
    if (err) return { err: { message: 'Неизвестная ошибка' } };

    return { data: data };
  }
}

export default Chat;
