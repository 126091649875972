import { Audio, Image, Media, Video, Document } from 'shared/types';

export const fileCategory = {
  audio: 'audio',
  document: 'document',
  image: 'image',
  media: 'media',
  video: 'video',
} as const;

export type FileItem = Audio | Document | Image | Media | Video;

export const fileSlug = {
  files: 'files',
  audios: 'audios',
  documents: 'documents',
  images: 'images',
  medias: 'medias',
  videos: 'videos',
} as const;

export type FileSlug = (typeof fileSlug)[keyof typeof fileSlug];

export const fileCategories = Object.freeze<FileCategory[]>(
  <FileCategory[]>Object.keys(fileCategory)
);

export type FileCategory = (typeof fileCategory)[keyof typeof fileCategory];

export type FileExt = ImageExt | DocumentExt | AudioExt | VideoExt | MediaExt;

export type FileType =
  | AudioTypeAlias
  | ImageTypeAlias
  | MediaTypeAlias
  | VideoTypeAlias
  | DocumentTypeAlias;

export const audioExt = {
  wav: 'wav',
  mp3: 'mp3',
} as const;

export const audioExts = Object.freeze<AudioExt[]>(
  <AudioExt[]>Object.keys(audioExt)
);

export type AudioExt = (typeof audioExt)[keyof typeof audioExt];

export const audioTypeAlias = {
  leadFile: 'leadFile',
} as const;

export type AudioTypeAlias =
  (typeof audioTypeAlias)[keyof typeof audioTypeAlias];

export const documentExt = {
  txt: 'txt',
  rtf: 'rtf',
  doc: 'doc',
  docx: 'docx',
  odt: 'odt',
  pdf: 'pdf',
  ppt: 'ppt',
  pptx: 'pptx',
  xls: 'xls',
  xlsx: 'xlsx',
} as const;

export const documentExts = Object.freeze<DocumentExt[]>(
  <DocumentExt[]>Object.keys(documentExt)
);

export type DocumentExt = (typeof documentExt)[keyof typeof documentExt];

export const documentTypeAlias = {
  leadFile: 'leadFile',
} as const;

export type DocumentTypeAlias =
  (typeof documentTypeAlias)[keyof typeof documentTypeAlias];

export const imageExt = {
  jpg: 'jpg',
  jpeg: 'jpeg',
  png: 'png',
  gif: 'gif',
  bmp: 'bmp',
  psd: 'psd',
} as const;

export const imageExts = Object.freeze<ImageExt[]>(
  <ImageExt[]>Object.keys(imageExt)
);

export type ImageExt = (typeof imageExt)[keyof typeof imageExt];

export const imageTypeAlias = {
  userAvatar: 'userAvatar',
  organizationLogo: 'organizationLogo',
  organizationPhoto: 'organizationPhoto',
  advert: 'advert',
  leadFile: 'leadFile',
  catalog: 'catalog',
} as const;

export type ImageTypeAlias =
  (typeof imageTypeAlias)[keyof typeof imageTypeAlias];

export const mediaExt = {
  zip: 'zip',
  rar: 'rar',
} as const;

export const mediaExts = Object.freeze<MediaExt[]>(
  <MediaExt[]>Object.keys(mediaExt)
);

export type MediaExt = (typeof mediaExt)[keyof typeof mediaExt];

export const mediaTypeAlias = {
  leadFile: 'leadFile',
} as const;

export type MediaTypeAlias =
  (typeof mediaTypeAlias)[keyof typeof mediaTypeAlias];

export const videoExt = {
  avi: 'avi',
  mpeg: 'mpeg',
  mp4: 'mp4',
} as const;

export const videoExts = Object.freeze<VideoExt[]>(
  <VideoExt[]>Object.keys(videoExt)
);

export type VideoExt = (typeof videoExt)[keyof typeof videoExt];

export const videoTypeAlias = {
  leadFile: 'leadFile',
} as const;

export type VideoTypeAlias =
  (typeof videoTypeAlias)[keyof typeof videoTypeAlias];
