// chatQueries.ts
export const getPartnerQuery = (builder: any) => ({
  query: (filter: {}) => ({
    functionName: 'getPartnerChat',
    baseModule: 'Chat',
    data: filter,
  }),
});
export const getMessagesQuery = (builder: any) => ({
  query: (filter: {}) => ({
    functionName: 'getUnReadMessagesList',
    baseModule: 'Chat',
    data: filter,
  }),
});
export const delMessagesQuery = (builder: any) => ({
  query: (filter: {}) => ({
    functionName: 'deleteUnReadMessagesList',
    baseModule: 'Chat',
    data: filter,
  }),
});
