import axios from 'axios';
import * as Sentry from '@sentry/nextjs';
import { Session } from 'next-auth';
import { getSession } from 'next-auth/react';
import { WebApiResponse, RequestConfig } from 'shared/types/WebApiTypes';
import Advert from './Advert';
import Company from './Companies';
import Image from './Image';
import Leads from './Leads';
import Chat from './Chat';
import Categories from './Categories';
import User from './User';
import Dictionary from './Dictionary';
import Search from './Search';
import Files from './Files';
import Tender from './Tender';
import Notification from './Notification';
import { getQuery } from 'shared/helper';
import SMS from './SMS';
import Roles from './Roles';
import Project from './Project';
import Catalog from './Catalog';

const WebApi = (_session: Session | null = null): typeof apis => {
  // const BASE_PATH = process.env.NEXT_PUBLIC_APP_URL;
  const BASE_PATH = process.env.NEXT_PUBLIC_APP_URL;

  const _getSession = async () => {
    return _session && _session.user ? _session : await getSession();
  };

  const POST = 'POST',
    GET = 'GET',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH';

  const headerDict = async (ContentType?: string | undefined) => {
    // session = if (session.data) ? session : await getSession();
    const session = await _getSession();
    const ACCESS_TOKEN = session?.user?.accessToken;

    if (!ACCESS_TOKEN) {
      Sentry.captureException('NO_ACCESS_TOKEN');
      console.warn('NO_ACCESS_TOKEN');
    }

    Sentry.setUser({
      id: String(session?.user?.id),
      email: session?.user?.email,
      username: session?.user?.name,
    });
    return {
      'Content-Type': ContentType ? ContentType : 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT,PATCH',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      Authorization: ACCESS_TOKEN,
    };
  };

  // const requestOptions: any = {
  //   // headers: new Headers(await headerDict()),
  //   credentials: 'same-origin'
  // };

  /*
   *  _post({ 'advert' , {title: "pinky"} })
   */
  async function _post(
    api: string,
    params: {},
    config: RequestConfig = {}
  ): Promise<WebApiResponse<any>> {
    // requestOptions.method = POST;
    let apiUrl = `${BASE_PATH}/${api}`;
    const { contentType, ...axiosConfig } = config;
    Sentry.setContext('Request', {
      METHOD: POST,
      URL: apiUrl.toString(),
      params,
    });

    const request = axios.create({
      headers: await headerDict(contentType),
    });

    try {
      const result = await request.post(apiUrl, params, axiosConfig);
      Sentry.captureMessage('WebApiPostRequestResponse', {
        ...result?.data,
        ...result?.data?.data,
      });
      return { data: result };
    } catch (err: any) {
      Sentry.captureException('WebApiPostRequestError', {
        message: err.message,
        ...err.config,
      });
      console.warn(err);
      // emitter.Emit("systemMessage");
      return { err };
    }
  }

  /*
  Вызов PATCH
   */
  async function _patch(
    api: string,
    params: {},
    config: RequestConfig = {}
  ): Promise<WebApiResponse<any>> {
    // requestOptions.method = PATCH;
    let apiUrl = `${BASE_PATH}/${api}`;
    const { contentType, ...axiosConfig } = config;
    Sentry.setContext('Request', {
      METHOD: PATCH,
      URL: apiUrl.toString(),
      params,
    });

    const request = axios.create({
      headers: await headerDict(contentType),
    });

    try {
      const result = await request.patch(apiUrl, params, axiosConfig);
      Sentry.captureMessage('WebApiPostRequestResponse', {
        ...result?.data,
        ...result?.data?.data,
      });
      return { data: result };
    } catch (err: any) {
      Sentry.captureException('WebApiPostRequestError', {
        message: err.message,
        ...err.config,
      });
      console.warn(err);
      // emitter.Emit("systemMessage");
      return { err };
    }
  }

  /*
   *  _get({ 'advert' , {id: 1} })
   */
  async function _get(
    api: string,
    params?: {},
    config: RequestConfig = {}
  ): Promise<WebApiResponse<any>> {
    // requestOptions.method = GET;

    let apiUrl = new URL(`${BASE_PATH}/${api}`);
    apiUrl.search = getQuery(params);
    Sentry.setContext('WebApiRequest', {
      METHOD: GET,
      URL: apiUrl.toString(),
      params,
    });
    const request = axios.create({
      headers: await headerDict(),
      // params: params,
      withCredentials: true,
    });
    try {
      const result = await request.get(apiUrl.toString(), config);
      Sentry.captureMessage('WebApiGetRequestResponse', {
        ...result?.data,
        ...result?.data?.data,
      });
      return { data: result?.data?.data };
    } catch (err: any) {
      Sentry.captureException('WebApiGetRequestError', {
        message: err.message,
        ...err.config,
      });
      // emitter.Emit("systemMessage");
      return { err };
    }
  }

  /*
  Вызов PUT
   */
  async function _put(
    api: string,
    params: {},
    config: RequestConfig = {}
  ): Promise<WebApiResponse<any>> {
    let apiUrl = `${BASE_PATH}/${api}`;
    const { contentType, ...axiosConfig } = config;

    Sentry.setContext('Request', {
      METHOD: PUT,
      URL: apiUrl.toString(),
      params,
    });

    const request = axios.create({
      headers: await headerDict(contentType),
    });

    try {
      const result = await request.put(apiUrl, params, axiosConfig);
      Sentry.captureMessage('WebApiPutRequestResponse', {
        ...result?.data,
        ...result?.data?.data,
      });
      return { data: result };
    } catch (err: any) {
      Sentry.captureException('WebApiPutRequestError', {
        message: err.message,
        ...err.config,
      });
      console.warn(err);
      return { err };
    }
  }

  /*
  Вызов DELETE
   */
  async function _delete(
    api: string,
    params?: {},
    config: RequestConfig = {}
  ): Promise<WebApiResponse<any>> {
    let apiUrl = `${BASE_PATH}/${api}`;
    const { contentType, ...axiosConfig } = config;

    Sentry.setContext('Request', {
      METHOD: 'DELETE',
      URL: apiUrl.toString(),
      params,
    });

    const request = axios.create({
      headers: await headerDict(contentType),
    });

    try {
      const result = await request.delete(apiUrl, {
        data: params,
        ...axiosConfig,
      });
      Sentry.captureMessage('WebApiDeleteRequestResponse', {
        ...result?.data,
        ...result?.data?.data,
      });
      return { data: result };
    } catch (err: any) {
      Sentry.captureException('WebApiDeleteRequestError', {
        message: err.message,
        ...err.config,
      });
      console.warn(err);
      return { err };
    }
  }

  // const responeAdv = await fetch(`${PATH}/adverts?advertId=${id}&relation=true`, requestOptions)
  // let json = await responeAdv.json();
  // let dataAdv = json.data
  const apis = {
    Advert: new Advert({
      _post,
      _get,
      _put,
      _delete,
      _patch,
      _getSession,
    }),
    Company: new Company({
      _post,
      _get,
      _put,
      _delete,
      _patch,
      _getSession,
    }),
    Image: new Image({
      _post,
      _get,
      _put,
      _delete,
      _patch,
      _getSession,
    }),
    Leads: new Leads({
      _post,
      _get,
      _put,
      _delete,
      _patch,
      _getSession,
    }),
    Chat: new Chat({
      _post,
      _get,
      _put,
      _delete,
      _patch,
      _getSession,
    }),
    Categories: new Categories({
      _post,
      _get,
      _put,
      _delete,
      _patch,
      _getSession,
    }),
    User: new User({
      _post,
      _get,
      _put,
      _delete,
      _patch,
      _getSession,
    }),
    Dictionary: new Dictionary({
      _post,
      _get,
      _put,
      _delete,
      _patch,
      _getSession,
    }),
    Search: new Search({
      _post,
      _get,
      _put,
      _delete,
      _patch,
      _getSession,
    }),
    Files: new Files({
      _post,
      _get,
      _put,
      _delete,
      _patch,
      _getSession,
    }),
    Tender: new Tender({
      _post,
      _get,
      _put,
      _delete,
      _patch,
      _getSession,
    }),
    Notification: new Notification({
      _post,
      _get,
      _put,
      _delete,
      _patch,
      _getSession,
    }),
    SMS: new SMS({
      _post,
      _get,
      _put,
      _delete,
      _patch,
      _getSession,
    }),
    Roles: new Roles({
      _post,
      _get,
      _put,
      _delete,
      _patch,
      _getSession,
    }),
    Project: new Project({
      _post,
      _get,
      _put,
      _delete,
      _patch,
      _getSession,
    }),
    Catalog: new Catalog({
      _post,
      _get,
      _put,
      _delete,
      _patch,
      _getSession,
    }),
  };
  return apis;
};

export default WebApi;
