import {
  WebApiResponse,
} from 'shared/types/WebApiTypes';
import BaseEntity from '../BaseEntity';

/*
В методах класса не должно быть ReactNode вызовов!
Они могут использоваться на сервере!
 */
class Search extends BaseEntity {
  protected API_SLUG: string = 'search/public';

  async create(params?: any){
    const { err, data } = await this.post(this.API_SLUG, params);
    if (err)
      return { err: { message: (err as any).response.data.error.message } };
    if (data?.data?.status !== 200) {
      return { err: { message: 'Неизвестная ошибка' } };
    }
    return { data };
  }
}

export default Search;
