import { createApi } from '@reduxjs/toolkit/query/react';
import WebApi from '../../../services';
import { getDictionaryQuery } from './Dictionary';
import { getCategoryQuery } from './Category';
import {
  getConnectsWithUserAndCompany,
  getInviteList,
  getLocationsQuery,
  getPartnersList,
} from './Сompany';
import { getRoleQuery } from './Role';
import { getAdverQuery, getLeadStatusQuery } from './Advert';
import { delMessagesQuery, getMessagesQuery, getPartnerQuery } from './Chat';
import { getDepartmentList } from './Deportament';
import { getTendersList } from './Tenders';
import { getAdvertsCatalogList, getCatalogList } from './Catalog';

interface BaseQuery {
  functionName?: any;
  baseModule?: any;
  data?: any;
}
export const chatApi = createApi({
  reducerPath: 'chatsApi',
  baseQuery: async ({ functionName, baseModule, data }: BaseQuery) => {
    try {
      const response = await (<any>WebApi())[baseModule][functionName](data);
      return { data: response.data ?? response };
    } catch (error) {
      return { error };
    }
  },
  endpoints: (builder) => ({
    getChatsList: builder.query<any, any>({
      query: (data) => ({
        functionName: 'getAllChats',
        baseModule: 'Chat',
        data,
      }),
    }),
    getLeadsList: builder.query<any, any>({
      query: (filter: {}) => {
        return {
          functionName: 'getList',
          baseModule: 'Leads',
          data: filter,
        };
      },
    }),
    getLead: builder.query<any, any>({
      query: (id: number) => {
        return {
          functionName: 'getList',
          baseModule: 'Leads',
          data: id,
        };
      },
    }),
    getUsers: builder.query<any, any>({
      query: (filter: {}) => {
        return {
          functionName: 'getList',
          baseModule: 'User',
          data: filter,
        };
      },
    }),
    getChatUsers: builder.query<any, any>({
      query: (filter: {}) => {
        return {
          functionName: 'getChatUsers',
          baseModule: 'Chat',
          data: filter,
        };
      },
    }),
    getChat: builder.query<any, string>({
      query: (chatId: string) => ({
        functionName: 'getChatsList',
        baseModule: 'Chat',
        data: chatId,
      }),
    }),
    getOrganizationByUser: builder.query<any, any>({
      query: (data) => ({
        functionName: 'getOne',
        baseModule: 'Company',
        data,
      }),
    }),
    getRoleList: builder.query<any, any>({
      query: (data) => ({
        functionName: 'getRoleList',
        baseModule: 'Company',
        data,
      }),
    }),
    getChatAdvert: builder.query<any, any>({
      query: (leadId: string) => ({
        functionName: 'getChatAdvert',
        baseModule: 'Chat',
        data: leadId,
      }),
    }),
    getMessages: builder.query<any, string>({
      query: (chatId: string) => ({
        functionName: 'getMessagesList',
        baseModule: 'Chat',
        data: chatId,
      }),
    }),
    postMessage: builder.query<any, any>({
      query: (props: any) => ({
        functionName: 'createMessages',
        baseModule: 'Chat',
        data: props,
      }),
    }),
    addUserInChat: builder.query<any, any>({
      query: (filter: {}) => {
        return {
          functionName: 'createChatsUsers',
          baseModule: 'Chat',
          data: filter,
        };
      },
    }),
    removeUserFromChat: builder.query<any, any>({
      query: (filter: {}) => {
        return {
          functionName: 'removeChatUser',
          baseModule: 'Chat',
          data: filter,
        };
      },
    }),
    getParentChat: builder.query<any, any>(getPartnerQuery(builder)),
    getDictionary: builder.query<any, any>(getDictionaryQuery(builder)),
    getCategory: builder.query<any, any>(getCategoryQuery(builder)),
    getConnectsWithUserAndCompany: builder.query<any, any>(
      getConnectsWithUserAndCompany(builder)
    ),
    getRole: builder.query<any, any>(getRoleQuery(builder)),
    getAdvert: builder.query<any, any>(getAdverQuery(builder)),
    getPartners: builder.query<any, any>(getPartnersList(builder)),
    getDepartment: builder.query<any, any>(getDepartmentList(builder)),
    getTendersList: builder.query<any, any>(getTendersList(builder)),
    getInvetes: builder.query<any, any>(getInviteList(builder)),
    getAdvertsCatalog: builder.query<any, any>(getAdvertsCatalogList(builder)),
    getCatalog: builder.query<any, any>(getCatalogList(builder)),
    getUnReadMessages: builder.query<any, any>(getMessagesQuery(builder)),
    delUnReadMessages: builder.query<any, any>(delMessagesQuery(builder)),
    getLocations: builder.query<any, any>(getLocationsQuery(builder)),
    getLeadStatus: builder.query<any, any>(getLeadStatusQuery(builder)),
  }),
});

export const {
  useGetChatsListQuery,
  useGetLeadsListQuery,
  useGetInvetesQuery,
  useLazyGetInvetesQuery,
  useGetLeadQuery,
  useGetPartnersQuery,
  useGetTendersListQuery,
  useGetLocationsQuery,
  useGetUnReadMessagesQuery,
  useLazyGetTendersListQuery,
  useLazyGetPartnersQuery,
  useGetUsersQuery,
  useGetDictionaryQuery,
  useGetCategoryQuery,
  useLazyGetUsersQuery,
  useGetChatUsersQuery,
  useGetLeadStatusQuery,
  useLazyGetChatUsersQuery,
  useLazyGetLeadsListQuery,
  useLazyGetChatsListQuery,
  useGetChatQuery,
  useGetAdvertQuery,
  useLazyGetChatQuery,
  usePostMessageQuery,
  useLazyPostMessageQuery,
  useGetMessagesQuery,
  useLazyGetMessagesQuery,
  useLazyGetChatAdvertQuery,
  useLazyAddUserInChatQuery,
  useLazyDelUnReadMessagesQuery,
  useGetRoleQuery,
  useGetRoleListQuery,
  useGetConnectsWithUserAndCompanyQuery,
  useGetOrganizationByUserQuery,
  useLazyRemoveUserFromChatQuery,
  useGetParentChatQuery,
  useLazyGetParentChatQuery,
  useGetDepartmentQuery,
  useLazyGetDepartmentQuery,
  useGetAdvertsCatalogQuery,
  useGetCatalogQuery,
} = chatApi;
